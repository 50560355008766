/* ========================================================================
 * Apricot's Accordion
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/accordion.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Accordion
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {String} data.headingClass
 * @param {String} data.panelClass
 * @param {Boolean} data.multiOpen
 * @param {Element} data.targetElem
 * @param {String} data.triggerClass
 * @param {String} data.targetPanelClass
 * @param {String} data.triggerLabel
 * @param {String} data.triggerLabelActive
 * @param {Number} data.heightAdjustment
 * @param {Boolean} data.animation
 * @param {Function} data.callBack
 * @param {Function} data.onChange
 * @returns {{adjustHeight: Function}}
 * @returns {{setPanelState: Function}}
 * @returns {{destroy: Function}}
 */

const Accordion = (data = {}) => {
  const defaultData = {
    elem: null,
    headingClass: "cb-accordion-heading",
    panelClass: "cb-accordion-panel",
    multiOpen: true,

    trigger: false,
    targetElem: null,
    triggerClass: "cb-accordion-trigger",
    targetPanelClass: "cb-accordion-trigger-panel",
    triggerLabel: "See More",
    triggerLabelActive: "See Less",

    heightAdjustment: 0,
    animation: true,
    nested: false,
    controlsNested: false,
    callBack: null,
    onChange: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let headingClass = data.headingClass;
  let panelClass = data.panelClass;
  let multiOpen = data.multiOpen;
  let nested = data.nested;
  let callBack = data.callBack;
  let onChange = data.onChange;

  // Split
  let targetElem = data.targetElem;
  let triggerLabel = data.triggerLabel;
  let triggerLabelActive = data.triggerLabelActive;

  let trigger = Utils.elemExists(targetElem);
  let resizeId = 0;
  let changeTime = 0;

  const isAndroid = Utils.OSName().name === "Android";

  if (!Utils.elemExists(elem)) return false;
  const init = () => {
    elem.accordion = "cb";

    if (trigger) {
      panelClass = data.targetPanelClass;
      triggerLabelVal(true);
    }

    if (nested) {
      markNested();
    }

    // Traditional Accordion
    if (!trigger) {
      Array.prototype.forEach.call(elem.getElementsByClassName(headingClass), (header) => {
        const panel = Utils.getNextSibling(header, "." + panelClass);

        const idH = Utils.attr(header, "id")
          ? Utils.attr(header, "id")
          : Utils.uniqueID(5, "apricot_");
        const idP = Utils.attr(panel, "id")
          ? Utils.attr(panel, "id")
          : Utils.uniqueID(5, "apricot_");

        Utils.attr(header, "id", idH);
        Utils.attr(header, "aria-expanded", "false");
        Utils.attr(header, "aria-controls", idP);

        Utils.attr(panel, "id", idP);
        Utils.attr(panel, "aria-labelledby", idH);
        Utils.attr(panel, "aria-hidden", "true");

        // Click event for header
        header.addEventListener("click", noTriggerClickHeader);
      });

      allEvents();
    } else {
      const panel = targetElem;

      const idH = Utils.attr(elem, "id") ? Utils.attr(elem, "id") : Utils.uniqueID(5, "apricot_");
      const idP = Utils.attr(panel, "id") ? Utils.attr(panel, "id") : Utils.uniqueID(5, "apricot_");

      Utils.attr(elem, "id", idH);
      Utils.attr(elem, "aria-expanded", "false");
      Utils.attr(elem, "aria-controls", idP);

      Utils.attr(panel, "id", idP);
      Utils.attr(panel, "aria-hidden", "true");
      Utils.attr(panel, "tabIndex", "-1");

      // Click event for header
      elem.addEventListener("click", triggerClickHeader);
    }

    // Adjust panel heigh to css animation
    setupHeight();

    // A11Y Link treatment
    a11yFocusableItems();

    if (useAnimation() && data.animation) {
      changeTime = 350;
      window.addEventListener("resize", delayedResize);
    }
  };

  const delayedResize = () => {
    clearTimeout(resizeId);
    resizeId = setTimeout(adjustAccHeight, 500);
  };

  const noTriggerClickHeader = (e, acc) => {
    // Click event for header
    if (e) {
      e.preventDefault();

      acc = e.currentTarget;
    }

    const panel = Utils.getNextSibling(acc, "." + panelClass);
    let panelHeight = panel.getAttribute("data-cb-height");

    if (Utils.elemExists(panel)) {
      // Reset if multi open option is off
      if (!multiOpen) {
        if (!Utils.hasClass(panel, "cb-in")) {
          resetAcc();
        }
      }

      if (Utils.hasClass(panel, "cb-in")) {
        panel.style.removeProperty("height");
      } else {
        if (panelHeight === null) {
          panelAdjustment(panel);
          panelHeight = panel.getAttribute("data-cb-height");
        }

        Utils.addClass(panel, "transition");
        panel.style.height = panelHeight + "px";
      }

      Utils.toggleClass(panel, "cb-in");
      Utils.toggleClass(acc, "cb-active");

      a11y(acc);
      if (nested) {
        adjustAccHeight();
      }

      let count = 0;
      if (useAnimation() && data.animation) {
        // dispatch event after transition is finished
        panel.addEventListener(
          "transitionend",
          (e) => {
            e.stopPropagation();
            if (count === 0 && e.propertyName === "height") {
              customNoTriggerEvent(acc, panel);
              count++;
            }
          },
          false
        );
      } else {
        customNoTriggerEvent(acc, panel);
      }

      onChange && setTimeout(onChange(panel), changeTime);
    }
  };

  const triggerClickHeader = (e) => {
    e.preventDefault();

    const acc = e.currentTarget;
    const panel = targetElem;
    const panelHeight = panel.getAttribute("data-cb-height");

    // It's already open
    if (Utils.hasClass(panel, "cb-in")) {
      panel.style.removeProperty("height");
      triggerLabelVal(true);
      a11yTabIndex(panel, 0);
    } else {
      Utils.addClass(panel, "transition");
      panel.style.height = panelHeight + "px";

      triggerLabelVal(false);
      a11yTabIndex(panel, -1);
    }

    Utils.toggleClass(panel, "cb-in");
    Utils.toggleClass(acc, "cb-active");

    a11y(acc);
    let count = 0;
    if (useAnimation() && data.animation) {
      // dispatch event after transition is finished
      panel.addEventListener("transitionend", (e) => {
        e.stopPropagation();
        if (count === 0 && e.propertyName === "height") {
          customTriggerEvent(acc, panel);
          count++;
        }
      });
    } else {
      customTriggerEvent(acc, panel);
    }

    onChange && setTimeout(onChange(panel), changeTime);
  };

  const customNoTriggerEvent = (acc, panel) => {
    const event = new CustomEvent("apricot_accordion");
    event.data = {
      open: Utils.hasClass(panel, "cb-in"),
    };
    acc.dispatchEvent(event);

    callBack && callBack(panel);
  };

  const customTriggerEvent = (acc, panel) => {
    const event = new CustomEvent("apricot_accordion");
    if (Utils.hasClass(panel, "cb-in")) {
      event.data = {
        open: true,
      };
      panel.focus();
    } else {
      event.data = {
        open: false,
      };
    }
    acc.dispatchEvent(event);

    callBack && callBack(panel);
  };

  const useAnimation = () => {
    return Utils.reduceMotionChanged() ? false : data.animation;
  };

  // open panel if needed
  const setupHeight = () => {
    if (trigger) {
      panelAdjustment(targetElem, true);
      if (Utils.hasClass(targetElem, "cb-tmp-in")) {
        elem.click();
        Utils.removeClass(targetElem, "cb-tmp-in");
      }
    } else {
      Array.prototype.forEach.call(elem.getElementsByClassName(panelClass), (panel) => {
        panelAdjustment(panel, true);

        if (Utils.hasClass(panel, "cb-tmp-in")) {
          const heading = Utils.parent(panel).querySelector("." + headingClass);
          noTriggerClickHeader(null, heading);

          Utils.removeClass(panel, "cb-tmp-in");
        }
      });
    }
  };

  // Panel heigh check after resize
  const adjustAccHeight = () => {
    if (trigger) {
      if (Utils.hasClass(targetElem, "cb-in")) {
        targetElem.style.height = "auto";
      }

      panelAdjustment(targetElem);
    } else {
      let panelList = elem.getElementsByClassName(panelClass);

      if (nested) {
        panelList = [].slice.call(panelList, 0).reverse();
      }
      Array.prototype.forEach.call(panelList, (panel) => {
        if (Utils.hasClass(panel, "cb-in")) {
          panel.style.height = "auto";
        }

        panelAdjustment(panel);
      });
    }
  };

  // 1: open
  // 0: close
  const setPanelState = (header, mode) => {
    if (!elem.contains(header)) return;

    if (Utils.hasClass(header, "cb-active") && !mode) {
      header.click();
    } else if (!Utils.hasClass(header, "cb-active") && mode) {
      header.click();
    }
  };

  const panelAdjustment = (panel, load) => {
    // A11Y
    if (useAnimation() && data.animation) {
      // Reset First
      Utils.removeClass(panel, "transition");
      if (Utils.hasClass(panel, "cb-in")) {
        Utils.addClass(panel, "cb-tmp-in");
      }

      // re-calculate
      Utils.addClass(panel, "cb-in");
      const height = panel.offsetHeight + data.heightAdjustment;

      Utils.attr(panel, "data-cb-height", height);
      Utils.removeClass(panel, "cb-in");

      if (!load) {
        if (Utils.hasClass(panel, "cb-tmp-in")) {
          Utils.addClass(panel, "cb-in");
          Utils.removeClass(panel, "cb-tmp-in");
          panel.style.height = `${height}px`;
        }
      }
    } else {
      if (Utils.hasClass(panel, "cb-in")) {
        Utils.addClass(panel, "cb-tmp-in");
        Utils.removeClass(panel, "cb-in");
      }
    }
  };

  const triggerLabelVal = (mode) => {
    const triggerLabelElem = elem.firstElementChild;
    if (!Utils.elemExists(triggerLabelElem)) return;

    if (mode) {
      triggerLabelElem.innerHTML = triggerLabel;
    } else {
      triggerLabelElem.innerHTML = triggerLabelActive;
    }
  };

  const allEvents = () => {
    if (elem.querySelector(".cb-accordion-collapse")) {
      elem.querySelector(".cb-accordion-collapse").addEventListener("click", clickAllEvents);
    }
    if (elem.querySelector(".cb-accordion-expand")) {
      elem.querySelector(".cb-accordion-expand").addEventListener("click", clickAllEvents);
    }
  };

  const clickAllEvents = (e) => {
    e.preventDefault();

    Array.prototype.forEach.call(elem.getElementsByClassName(headingClass), (header) => {
      if (Utils.hasClass(e.currentTarget, "cb-accordion-expand")) {
        if (!Utils.hasClass(header, "cb-active")) {
          if (!nested) {
            header.click();
          } else if (nested && data.controlsNested) {
            header.click();
          } else if (nested) {
            if (!Utils.hasClass(header, "cb-header-nested")) {
              header.click();
            }
          }
        }
      } else if (Utils.hasClass(e.currentTarget, "cb-accordion-collapse")) {
        if (Utils.hasClass(header, "cb-active")) {
          if (!nested) {
            header.click();
          } else if (nested && data.controlsNested) {
            header.click();
          } else if (nested) {
            if (!Utils.hasClass(header, "cb-header-nested")) {
              header.click();
            }
          }
        }
      }
    });
  };

  const markNested = () => {
    Array.prototype.forEach.call(elem.getElementsByClassName(headingClass), (header) => {
      if (Utils.getClosest(header, ".cb-accordion-panel-content")) {
        Utils.addClass(header, "cb-header-nested");
      }
    });
  };

  const a11y = (header) => {
    const panel = trigger ? targetElem : Utils.getNextSibling(header, "." + panelClass);
    const mode = Utils.hasClass(header, "cb-active");

    // mode - true: open
    // mode - false: close
    Utils.attr(header, "aria-expanded", String(mode));
    if (mode) {
      if (!isAndroid) {
        Utils.attr(panel, "aria-hidden", String(!mode));
      } else {
        Utils.removeAttr(panel, "aria-hidden");
      }
    } else {
      Utils.attr(panel, "aria-hidden", String(!mode));
    }

    // Hide all focusable tags
    Array.prototype.forEach.call(panel.querySelectorAll(Utils.FOCUSABLE_ELEMENTS), (a) => {
      Utils.attr(a, "tabIndex", mode ? "0" : "-1");
      Utils.attr(a, "aria-hidden", String(!mode));
    });

    a11yExpCol();
  };

  const a11yExpCol = () => {
    if (trigger) return;

    const exp = elem.querySelector(".cb-accordion-expand");
    const col = elem.querySelector(".cb-accordion-collapse");

    const colHead = elem.querySelectorAll('.cb-accordion-heading[aria-expanded="false"]').length;
    const expHead = elem.querySelectorAll('.cb-accordion-heading[aria-expanded="true"]').length;
    const head = elem.getElementsByClassName(headingClass).length;

    if (!exp || !col) return;

    if (head === expHead) {
      Utils.attr(exp, "aria-current", "true");
      Utils.removeAttr(col, "aria-current");
    } else if (head === colHead) {
      Utils.attr(col, "aria-current", "true");
      Utils.removeAttr(exp, "aria-current");
    } else {
      Utils.removeAttr(col, "aria-current");
      Utils.removeAttr(exp, "aria-current");
    }
  };

  const a11yFocusableItems = () => {
    if (trigger) {
      accFocusableNodes(targetElem);
      if (Utils.hasClass(targetElem, "cb-in")) {
        a11yTabIndex(targetElem, 0);
      } else {
        a11yTabIndex(targetElem, -1);
      }
    } else {
      Array.prototype.forEach.call(elem.getElementsByClassName(panelClass), (panel) => {
        accFocusableNodes(panel);
        if (Utils.hasClass(panel, "cb-in")) {
          a11yTabIndex(panel, 0);
        } else {
          a11yTabIndex(panel, -1);
        }
      });
    }
  };

  const accFocusableNodes = (panel) => {
    getFocusableNodes(panel).forEach((node) => {
      Utils.attr(node, "data-cb-focusable", "true");
    });
  };

  const a11yTabIndex = (panel, mode) => {
    getFocusableNodes(panel).forEach((node) => {
      Utils.attr(node, "tabIndex", mode);
    });
  };

  const getFocusableNodes = (panel) => {
    return panel.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);
  };

  const resetAcc = () => {
    const active = elem.getElementsByClassName("cb-active");
    const open = elem.getElementsByClassName("cb-in");

    Array.prototype.forEach.call(active, (activeHeader) => {
      Utils.removeClass(activeHeader, "cb-active");
      a11y(activeHeader);
    });

    Array.prototype.forEach.call(open, (openPanel) => {
      Utils.removeClass(openPanel, "cb-in");

      openPanel.style.removeProperty("height");
    });
  };

  const destroy = () => {
    if (elem.accordion === "cb") {
      elem.accordion = null;

      if (useAnimation() && data.animation) {
        window.removeEventListener("resize", delayedResize);
      }
      // Accordion controls
      if (elem.querySelector(".cb-accordion-collapse")) {
        elem.querySelector(".cb-accordion-collapse").removeEventListener("click", clickAllEvents);
        Utils.removeAttr(elem.querySelector(".cb-accordion-collapse"), "aria-current");
      }
      if (elem.querySelector(".cb-accordion-expand")) {
        elem.querySelector(".cb-accordion-expand").removeEventListener("click", clickAllEvents);
        Utils.removeAttr(elem.querySelector(".cb-accordion-expand"), "aria-current");
      }

      // Height adjustment
      Array.prototype.forEach.call(elem.getElementsByClassName(panelClass), (panel) => {
        panel.style.height = "";
      });

      if (!trigger) {
        Array.prototype.forEach.call(elem.getElementsByClassName(headingClass), (header) => {
          const panel = Utils.getNextSibling(header, "." + panelClass);

          Utils.removeAttr(header, "aria-expanded");
          Utils.removeAttr(header, "aria-controls");
          Utils.removeClass(header, "cb-active");

          Utils.removeAttr(panel, "aria-labelledby");
          Utils.removeAttr(panel, "aria-hidden");
          Utils.removeAttr(panel, "data-cb-height");

          header.removeEventListener("click", noTriggerClickHeader);
        });
      } else {
        const panel = targetElem;

        Utils.removeAttr(elem, "aria-expanded");
        Utils.removeAttr(elem, "aria-controls");

        Utils.removeClass(elem, "cb-active");

        Utils.removeAttr(panel, "aria-labelledby");
        Utils.removeAttr(panel, "aria-hidden");
        Utils.removeAttr(panel, "data-cb-height");
        panel.style.height = "auto";

        elem.removeEventListener("click", triggerClickHeader);
      }
    }
  };

  if (elem.accordion !== "cb") {
    init();
  }

  return {
    destroy: destroy,
    adjustHeight: adjustAccHeight,
    setPanelState: setPanelState,
  };
};

export default Accordion;
