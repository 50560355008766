/* ========================================================================
 * Apricot's Modals
 * ========================================================================
 *
 * It has the ability to display multiple modals at the same time
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/modal.scss";
import "../scss/includes/button.scss";

// javaScript
import Utils from "./CBUtils";
/**
 * Modal
 *
 * @class
 * @param {Object} data
 * @param {Element|String} data.elem
 * @param {Element|String} data.trigger
 * @param {Element|String} data.focusElem
 * @param {Boolean} data.videoModal
 * @param {String} data.closeAttr
 * @param {Boolean} data.disableFocus
 * @param {Boolean} data.disableScroll
 * @param {Boolean} data.disableHeightAdjustment
 * @param {Boolean} data.disableHeightAdjustmentAria
 * @param {Boolean} data.escClose
 * @param {Boolean} data.awaitOpenAnimation
 * @param {Boolean} data.awaitCloseAnimation
 * @param {Boolean} data.openAnimation
 * @param {Boolean} data.closeAnimation
 * @param {Boolean} data.controlled
 * @param {Boolean} data.analytics
 * @param {String} data.analyticsTitle
 * @param {Boolean} data.analyticsOnClose
 * @param {Function} data.onShow
 * @param {Function} data.onClose
 * @returns {{show: Function}}
 * @returns {{close: Function}}
 * @returns {{adjustHeight: Function}}
 * @returns {{destroy: Function}}
 */

const Modal = (data = {}) => {
  const defaultData = {
    elem: null,
    trigger: null,
    focusElem: null,
    videoModal: false,
    closeAttr: "data-cb-modal-close",
    disableFocus: false,
    disableScroll: false,
    disableHeightAdjustment: false,
    disableHeightAdjustmentAria: false,
    escClose: true,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    openAnimation: true,
    closeAnimation: true,
    controlled: false,
    analytics: false,
    analyticsTitle: null,
    analyticsOnClose: false,
    onShow: () => {},
    onClose: () => {},
  };
  data = {
    ...defaultData,
    ...data,
  };

  const elem = typeof data.elem === "string" ? document.getElementById(data.elem) : data.elem;

  if (!Utils.elemExists(elem)) return null;

  let trigger = null;
  let focusElem = null;
  let activeElement = null;

  let modalContainer = null;
  let modalHeader = null;
  let modalContent = null;
  let modalFooter = null;

  let elemId = "";
  let adjustableContent = null;
  let videoIframe = null;
  let videoSrc = "";
  let analyticsTitle = "";

  const init = () => {
    elem.modal = "cb";
    modalComponents();
    // Add Events
    addEventListeners();
    if (Utils.elemExists(trigger)) {
      trigger.addEventListener("click", showModal);
    }
  };

  const modalComponents = () => {
    trigger =
      typeof data.trigger === "string" ? document.getElementById(data.trigger) : data.trigger;
    focusElem =
      typeof data.focusElem === "string" ? document.getElementById(data.focusElem) : data.focusElem;

    modalContainer = elem.querySelector(".cb-modal-container");
    modalHeader = elem.querySelector(".cb-modal-header");
    modalContent = elem.querySelector(".cb-modal-content");
    modalFooter = elem.querySelector(".cb-modal-footer");

    // make sure Modal has ID
    elemId = Utils.attr(elem, "id") ? Utils.attr(elem, "id") : Utils.uniqueID(5, "apricot_");
    Utils.attr(elem, "id", elemId);

    if (modalContent) {
      if (modalContent.querySelector(".cb-notification")) {
        adjustableContent = modalContent.querySelector(".cb-notification-content");
      } else {
        adjustableContent = modalContent;
      }
    } else {
      console.warn("Apricot Modal: Seems like your modal has no content block.");
    }

    // needed for style adjustment
    if (modalHeader && modalHeader.querySelector(".cb-btn-close")) {
      Utils.addClass(modalHeader, "cb-modal-has-close");
    }

    // video Modal
    if (data.videoModal) {
      videoIframe = elem.querySelector("iframe");
      if (videoIframe) {
        videoSrc = Utils.attr(videoIframe, "data-cb-src");
      }
    }

    // activate analytics tracker
    if (data.analytics) {
      const title = elem.querySelector(".cb-modal-title");
      const dialogTitle = elem.querySelector(".cb-notification-title");
      analyticsTitle = data.analyticsTitle
        ? data.analyticsTitle
        : Utils.elemExists(title)
        ? title.textContent || title.innerText
        : Utils.elemExists(dialogTitle)
        ? dialogTitle.textContent || dialogTitle.innerText
        : `missing title - ${elemId}`;

      Utils.attr(modalContainer, "data-cbtrack-modal", analyticsTitle);
    }
  };

  const showModal = (e) => {
    if (e) e.preventDefault();
    // make sure we have all events
    addEventListeners();

    // this should be the trigger
    activeElement = document.activeElement;
    elem.setAttribute("aria-hidden", "false");

    if (data.videoModal) {
      if (videoIframe) {
        videoIframe.src = videoSrc;
      }
    }

    Utils.addClass(elem, "cb-open");

    if (data.awaitOpenAnimation) {
      // dispatch modal start show
      const event = new CustomEvent("apricot_modalShow_start");
      activeElement && activeElement.dispatchEvent(event);
      elem.dispatchEvent(event);

      // animationend event is fired when a CSS Animation has completed
      elem.addEventListener("animationend", startAnimation, false);
    } else {
      calculateHeight();
      setFocusToFirstNode();
      scrollBehaviour("disable");

      data.onShow && data.onShow(elem);

      // dispatch modal show
      const event = new CustomEvent("apricot_modalShow");
      activeElement && activeElement.dispatchEvent(event);
      elem.dispatchEvent(event);
    }

    // trigger analytics tracker
    if (data.analytics) {
      trackAnalytics(true);
    }

    const body = document.getElementsByTagName("body")[0];
    Utils.addClass(body, "cb-modal-open");
  };

  const closeModal = (options) => {
    if (!elem) return;
    elem.setAttribute("aria-hidden", "true");

    // make sure the src is removed when modal closes
    if (data.videoModal) {
      if (videoIframe) {
        videoIframe.src = "";
      }
    }

    scrollBehaviour("enable");

    // set focus to active elem, A11Y
    activeElement && activeElement.focus();

    if (options && options.onClose) {
      options.onClose(elem, options.source);
    } else if (data.onClose) {
      data.onClose(elem, options.source);
    }

    if (data.awaitCloseAnimation) {
      // dispatch modal start close
      const event = new CustomEvent("apricot_modalClose_start");
      activeElement && activeElement.dispatchEvent(event);
      elem.dispatchEvent(event);

      // animationend event is fired when a CSS Animation has completed
      elem.addEventListener("animationend", endAnimation, false);
    } else {
      Utils.removeClass(elem, "cb-open");

      // dispatch modal close
      const event = new CustomEvent("apricot_modalClose");
      activeElement && activeElement.dispatchEvent(event);
      elem.dispatchEvent(event);
    }

    // trigger analytics tracker
    if (data.analytics && data.analyticsOnClose) {
      trackAnalytics(false);
    }

    const body = document.getElementsByTagName("body")[0];
    Utils.removeClass(body, "cb-modal-open");

    resetHeight();
  };

  const calculateHeight = () => {
    if (!Utils.hasClass(elem, "cb-open")) return;

    if (data.disableHeightAdjustment) return;

    // no content
    if (!adjustableContent) return;
    // empty content
    if (!adjustableContent.hasChildNodes()) return;
    if (!Utils.hasClass(elem, "cb-open")) return;

    resetHeight();

    // Only calculate when the modal is open
    if (data.videoModal) {
      let pageHeight = window.innerHeight;
      let pageWidth = window.innerWidth;
      let height = parseInt(pageHeight, 10) - 96;
      let width = parseInt(pageWidth, 10) - 96;
      let w = 0;
      let h = 0;

      if (pageWidth < pageHeight) {
        w = (width * 98) / 100;
        h = Math.round((w * 9) / 16);
      } else {
        h = (height * 98) / 100;
        w = Math.round((h * 16) / 9);
      }

      modalContainer.style.width = w + "px";
      modalContainer.style.height = h + "px";
    } else if (Utils.hasClass(elem, "cb-open")) {
      let ch = Utils.height(modalContainer);
      let mh = Utils.height(adjustableContent);
      let hh = 0;
      let fh = 0;
      let newHeight = 0;

      if (modalHeader) hh = Utils.outerHeight(modalHeader);
      if (modalFooter) {
        fh = Utils.outerHeight(modalFooter);
      } else {
        // GS-9130
        // fh = 24;
        fh = 0;
      }
      let mpAdjust = 24 * 3;
      let header = null;

      if (modalContent.querySelector(".cb-notification")) {
        header = modalContent.querySelector(".cb-notification-header");
      }

      if (modalContent.querySelector(".cb-notification")) {
        fh = 0;
        mpAdjust = 24 * 2 + 12;
        hh = Utils.outerHeight(header);
      }

      newHeight = ch - mpAdjust - hh - fh;

      if (mh > newHeight) {
        adjustableContent.style.overflowY = "auto";
        adjustableContent.style.height = newHeight + "px";
        if (!data.disableHeightAdjustmentAria) {
          Utils.attr(adjustableContent, "tabindex", "0");
          Utils.attr(adjustableContent, "role", "region");
          Utils.attr(adjustableContent, "aria-label", "scrollable content");
        }
      } else {
        resetHeight();
      }
    }
  };

  const resetHeight = () => {
    if (data.videoModal) {
      if (!modalContainer) return;
      modalContainer.style.overflowY = "hidden";
      modalContainer.style.height = "auto";
    } else {
      if (!adjustableContent) return;
      if (!adjustableContent.hasChildNodes()) return;

      adjustableContent.style.overflowY = "hidden";
      adjustableContent.style.height = "auto";
      if (!data.disableHeightAdjustmentAria) {
        Utils.removeAttr(adjustableContent, "tabindex");
        Utils.removeAttr(adjustableContent, "role");
        Utils.removeAttr(adjustableContent, "aria-label");
      }
    }
  };

  const getFocusableNodes = () => {
    const nodes = elem.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);

    return Array(...nodes);
  };

  const setFocusToFirstNode = () => {
    if (data.disableFocus) return;

    const dialog = elem.querySelector('div[role="dialog"]');
    if (dialog) {
      // set focus to specific element when requested
      if (Utils.elemExists(focusElem)) {
        focusElem.focus();
      } else {
        Utils.attr(dialog, "tabIndex", "0");
        dialog.focus();
      }
    } else {
      const focusableNodes = getFocusableNodes();
      if (focusableNodes.length) focusableNodes[0].focus();
    }
  };

  const maintainFocus = (event) => {
    const focusableNodes = getFocusableNodes();

    // if disableFocus is true
    if (!elem.contains(document.activeElement)) {
      focusableNodes[0].focus();
    } else {
      const focusedItemIndex = focusableNodes.indexOf(document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableNodes[focusableNodes.length - 1].focus();
        event.preventDefault();
      }

      if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
        focusableNodes[0].focus();
        event.preventDefault();
      }
    }
  };

  // true: onShow
  // false: onClose
  const trackAnalytics = (mode) => {
    const eventName = mode ? "cbTrack-modalOpen" : "cbTrack-modalClose";
    document.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        detail: {
          modalName: analyticsTitle,
          modalEl: modalContainer,
        },
      })
    );
  };

  const modalOnClick = (event) => {
    let node = event.target;
    const parent = Utils.parent(node);
    if (parent.tagName === "BUTTON") {
      node = parent;
    }
    if (node.hasAttribute(data.closeAttr)) {
      let src = "";
      if (Utils.hasClass(node, "cb-modal-overlay")) {
        src = "overlay";
      } else if (Utils.hasClass(node, "cb-btn-close") && Utils.hasClass(node, "cb-btn-greyscale")) {
        src = "close";
      } else if (Utils.hasClass(node, "cb-btn")) {
        const id = `_${Utils.attr(node, "id")}` || "";
        src = `button${id}`;
      }
      closeModal(src && { source: src });
      event.preventDefault();
    }
  };

  const modalOnKeyDown = (event) => {
    const body = document.getElementsByTagName("body")[0];
    // If toast esc is not in place
    if (
      event.keyCode === 27 &&
      data.escClose &&
      Utils.hasClass(elem, "cb-open") &&
      !Utils.attr(body, "data-cb-esc")
    ) {
      closeModal({ source: "keyboard" });
    }

    if (!Utils.hasClass(elem, "cb-photo-gallery-modal")) {
      if (event.keyCode === 9) maintainFocus(event);
    }
  };

  const windowOnResize = () => {
    calculateHeight();
  };

  const addEventListeners = () => {
    if (elem.modalEvent) return;
    elem.modalEvent = true;

    if (data.openAnimation === false) {
      Utils.addClass(elem, "cb-no-animation-open");
    }

    if (data.closeAnimation === false) {
      Utils.addClass(elem, "cb-no-animation-close");
    }

    elem.addEventListener("touchstart", modalOnClick, {
      passive: true,
    });
    elem.addEventListener("click", modalOnClick);
    document.addEventListener("keydown", modalOnKeyDown);
    window.addEventListener("resize", windowOnResize);
  };

  const removeEventListeners = () => {
    elem.modalEvent = null;
    elem.removeEventListener("touchstart", modalOnClick, {
      passive: true,
    });
    elem.removeEventListener("click", modalOnClick);
    document.removeEventListener("keydown", modalOnKeyDown);
    window.removeEventListener("resize", windowOnResize);
  };

  const startAnimation = () => {
    calculateHeight();
    setFocusToFirstNode();
    scrollBehaviour("disable");
    data.onShow && data.onShow(elem);

    elem.removeEventListener("animationend", startAnimation, false);

    // dispatch modal show
    const event = new CustomEvent("apricot_modalShow");
    activeElement && activeElement.dispatchEvent(event);
    elem.dispatchEvent(event);
  };

  const endAnimation = () => {
    Utils.removeClass(elem, "cb-open");
    elem.removeEventListener("animationend", endAnimation, false);

    // dispatch modal close
    const event = new CustomEvent("apricot_modalClose");
    activeElement && activeElement.dispatchEvent(event);
    elem.dispatchEvent(event);
  };

  // add/remove scroll option from body
  const scrollBehaviour = (toggle) => {
    if (!data.disableScroll) return;
    const body = document.querySelector("body");
    switch (toggle) {
      case "enable":
        body.style = {
          ...body.style,
          ...{
            overflow: "",
            height: "",
          },
        };
        break;
      case "disable":
        body.style = {
          ...body.style,
          ...{
            overflow: "hidden",
            height: "100vh",
          },
        };
        break;
      default:
    }
  };

  /**
   * Shows modal
   * @return {void}
   */
  const show = () => {
    showModal();
  };

  /**
   * Closes modal
   * @param {Object} options
   * @param  {Function} options.onClose [callback function to call when modal closes]
   * @return {void}
   */
  const close = (options = {}) => {
    closeModal(options);
    removeEventListeners();
  };

  /**
   * Adjust Height for active modal
   * @return {void}
   */
  const adjustHeight = () => {
    if (elem && Utils.hasClass(elem, "cb-open")) {
      calculateHeight();
    }
  };

  const destroy = () => {
    if (elem.modal === "cb") {
      elem.modal = null;

      removeEventListeners();

      if (Utils.elemExists(trigger)) {
        trigger.removeEventListener("click", showModal);
      }
    }
  };

  if (elem.modal !== "cb") {
    init();
  } else {
    modalComponents();
  }

  return {
    show,
    close,
    adjustHeight,
    destroy,
  };
};

export default Modal;
